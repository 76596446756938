import React, { useEffect, useState } from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import styles from './JobsStatics.module.css';
import { API_URL, getToken } from '../../Helpers/constants';
import { Link } from 'react-router-dom';

export default function JobsStatics() {
    const token = getToken();

    const [jobData, setJobData] = useState(null);
    const [joinedData, setJoinedData] = useState([]);
    const [webinarStats, setWebinarStats] = useState(null);
    const [meetingStats, setMeetingStats] = useState(null);
    const [taskSubmissions, setTaskSubmissions] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const myHeaders = new Headers();
                myHeaders.append("accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${token}`);
                myHeaders.append("X-CSRFToken", "wK7YB2jTa3u12aKS64ttydcuEpxgU4ihFeZBv33UyDVhyg3qsqtYK2qy5bGOT2Kz");

                const requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow",
                };

                // Fetch job statistics
                const jobResponse = await fetch(`${API_URL}/students/freelance-status/`, requestOptions);
                const jobResult = await jobResponse.json();
                setJobData(jobResult);

                // Fetch joined status
                const joinedResponse = await fetch(`${API_URL}/students-joined/`, requestOptions);
                const joinedResult = await joinedResponse.json();
                setJoinedData([
                    { label: "Joined", value: joinedResult.joined_percentage, count: joinedResult.joined_count },
                    { label: "Not Joined", value: joinedResult.not_joined_percentage, count: joinedResult.not_joined_count },
                ]);

                // Fetch webinar statistics
                const webinarResponse = await fetch(`${API_URL}/webinar-statistics/`, requestOptions);
                const webinarResult = await webinarResponse.json();
                setWebinarStats(webinarResult);

                // Fetch meeting statistics
                const meetingResponse = await fetch(`${API_URL}/meeting-statistics/`, requestOptions);
                const meetingResult = await meetingResponse.json();
                setMeetingStats(meetingResult);

                // Fetch task submissions
                const taskResponse = await fetch(`${API_URL}/task-statistics/`, requestOptions);
                const taskResult = await taskResponse.json();
                setTaskSubmissions(taskResult.task_submissions);

                setLoading(false);
            } catch (err) {
                console.error("Error fetching data:", err);
                setError("Failed to fetch data");
                setLoading(false);
            }
        };

        fetchData();
    }, [token]);

    if (loading) {
        return <div>Loading Dashboard...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    // Chart data preparation
    const jobChartData = [
        { label: "Full-Time", value: jobData.full_time_jobs_percentage, count: jobData.full_time_jobs_count },
        { label: "Part-Time", value: jobData.part_time_jobs_percentage, count: jobData.part_time_jobs_count },
        { label: "Freelance", value: jobData.freelance_jobs_percentage, count: jobData.freelance_jobs_count },
    ];

    const meetingRatingData = [
        { label: "Rating 1", value: meetingStats.rating_1_percentage || 0 },
        { label: "Rating 2", value: meetingStats.rating_2_percentage || 0 },
        { label: "Rating 3", value: meetingStats.rating_3_percentage || 0 },
        { label: "Rating 4", value: meetingStats.rating_4_percentage || 0 },
        { label: "Rating 5", value: meetingStats.rating_5_percentage || 0 },
    ];

    const arcLabelFormatter = (item) => `${item.value.toFixed(1)}% (${item.count})`;

    const isMeetingRatingsZero = meetingRatingData.every(data => data.value === 0);
    const isWebinarZero = webinarStats.joined_percentage === 0 && webinarStats.not_joined_percentage === 0;

    return (
        <div className={styles.dashboard}>
            <h1 className={styles.title}>Overview</h1>

            <div className={styles.cardsContainer}>
                <div className={styles.card}>
                    <h3>Students Without Jobs</h3>
                    <p className={styles.count}>{jobData.zero_jobs}</p>
                </div>
                <div className={styles.card}>
                    <h3>Students Finished Jobs</h3>
                    <p className={styles.count}>{jobData.finished_course}</p>
                    <Link to="/student-passed" className={styles.link}>Show Student Passed</Link>
                </div>
                <div className={styles.card}>
                    <h3>Students In Progress Jobs</h3>
                    <p className={styles.count}>{jobData.in_progress}</p>
                </div>
                <div className={styles.card}>
                    <h3>Task Submissions</h3>
                    <p className={styles.count}>{taskSubmissions}</p>
                </div>
                <div className={styles.card}>
                    <h3>Total Webinars</h3>
                    <p className={styles.count}>{webinarStats.total_webinars}</p>
                </div>
                <div className={styles.card}>
                    <h3>Total Meetings</h3>
                    <p className={styles.count}>{meetingStats.total_meetings}</p>
                </div>
            </div>

            <div className={styles.chartsContainer}>
                <div className={styles.chartItem}>
                    <h2>Job Status</h2>
                    <div className={styles.chartComponent}>
                        <PieChart
                            series={[{ data: jobChartData, arcLabel: arcLabelFormatter, arcLabelMinAngle: 35, arcLabelRadius: '60%' }]}
                            sx={{ [`& .${pieArcLabelClasses.root}`]: { fontWeight: 'bold' } }}
                            {...size}
                        />
                    </div>
                </div>
                <div className={styles.chartItem}>
                    <h2>Students Joined Status</h2>
                    <div className={styles.chartComponent}>
                        <PieChart
                            series={[{ data: joinedData, arcLabel: arcLabelFormatter, arcLabelMinAngle: 35, arcLabelRadius: '60%' }]}
                            sx={{ [`& .${pieArcLabelClasses.root}`]: { fontWeight: 'bold' } }}
                            {...size}
                        />
                    </div>
                </div>
                <div className={styles.chartItem}>
                    <h2>Webinar Status</h2>
                    {isWebinarZero ? <div>No webinar data available (0%)</div> :
                        <div className={styles.chartComponent}>

                            <PieChart
                                series={[{ data: [{ label: "Joined", value: webinarStats.joined_percentage }, { label: "Not Joined", value: webinarStats.not_joined_percentage }], arcLabel: arcLabelFormatter, arcLabelMinAngle: 35, arcLabelRadius: '60%' }]}
                                sx={{ [`& .${pieArcLabelClasses.root}`]: { fontWeight: 'bold' } }}
                                {...size}
                            />
                        </div>
                    }
                </div>
                <div className={styles.chartItem}>
                    <h2>Meeting Ratings</h2>
                    {isMeetingRatingsZero ? <div>No ratings data available (0%)</div> :
                        <div className={styles.chartComponent}>

                            <PieChart
                                series={[{ data: meetingRatingData, arcLabel: arcLabelFormatter, arcLabelMinAngle: 35, arcLabelRadius: '60%' }]}
                                sx={{ [`& .${pieArcLabelClasses.root}`]: { fontWeight: 'bold' } }}
                                {...size}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

const size = {
    width: 500,
    height: 250,
};
