import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './MeNavbar.module.css';
import logo1 from '../../assets/AGEN.png';
import logo2 from '../../assets/MCEN.png';
import logo3 from '../../assets/depiEN.png';
import logo4 from '../../assets/logo2.png';
import SubNavbar from '../SubNavbar/SubNavbar';

const MeNavbar = () => {
    const location = useLocation();

    // Hide Navbar if pathname is "/login"
    if (location.pathname === '/login') {
        return null;
    }
    return (
        <>
            <nav className={styles.navbar}>
                <div className={styles.logoContainer}>
                    <img src={logo1} alt="Logo 1" className={styles.logo} />
                    <img src={logo2} alt="Logo 2" className={styles.logo2} />
                </div>
                <div className={styles.navLink}>
                    <NavLink
                        to="/student-dashboard"
                        className={({ isActive }) => isActive ? styles.active : undefined}
                    >
                        Student Dashboard
                    </NavLink>
                    <NavLink
                        to="/instructors-dashboard"
                        className={({ isActive }) => isActive ? styles.active : undefined}
                    >
                        Coach dashboard
                    </NavLink>
                    <NavLink
                        to="/live-tracking-me"
                        className={({ isActive }) => isActive ? styles.active : undefined}
                    >
                        Live Tracking
                    </NavLink>
                    <NavLink
                        to="/freelance-tracking"
                        className={({ isActive }) => isActive ? styles.active : undefined}
                    >
                        Freelance Tracking
                    </NavLink>


                </div>
                <div className={styles.logoContainer}>
                    <img src={logo3} alt="Logo 3" className={styles.logo} />
                    <a href="https://eyouthlearning.com/" target="_blank" rel='noreferrer'>
                        <img src={logo4} alt="Logo 4" className={styles.logo1} />
                    </a>
                </div>
            </nav>
            <SubNavbar />
        </>
    );
};

export default MeNavbar;
