import { Tab, Tabs } from '@mui/material'; // Importing Tabs and Tab from Material UI
import React, { useEffect, useState } from 'react';
import { getTasks, submitTask } from '../../API/Api';
import deadlineIcon from "../../assets/date.svg";
import descriptionIcon from "../../assets/description.svg";
import { getToken } from '../../Helpers/constants';
import styles from './Tasks.module.css';

const TasksPage = () => {
    const [completeTasks, setCompleteTasks] = useState([]);
    const [uncompleteTasks, setUncompleteTasks] = useState([]);
    const [file, setFile] = useState({});
    const [urls, setUrls] = useState({});
    const [errors, setErrors] = useState({});
    const [tabValue, setTabValue] = useState(0);
    const token = getToken();

    useEffect(() => {
        async function fetchTasks() {
            try {
                const tasksData = await getTasks(token);
                setCompleteTasks(tasksData.complete_tasks);
                setUncompleteTasks(tasksData.uncomplete_tasks);
            } catch (error) {
                console.error("Failed to fetch tasks:", error);
            }
        }

        fetchTasks();
    }, [token]);

    const validateUrl = (url) => {
        const urlPattern = /^https:\/\/[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
        return urlPattern.test(url);
    };

    const handleFileChange = (taskId, event) => {
        setFile({ ...file, [taskId]: event.target.files[0] });
    };

    const handleUrlChange = (taskId, event) => {
        setUrls({ ...urls, [taskId]: event.target.value });
    };

    const handleSubmit = async (taskId) => {
        if (!validateUrl(urls[taskId])) {
            setErrors({ ...errors, [taskId]: "Please enter a valid URL that starts with https://" });
            return;
        }

        if (!file[taskId] || !urls[taskId]) {
            setErrors({ ...errors, [taskId]: "Please upload a file and provide a URL." });
            return;
        }

        try {
            //eslint-disable-next-line
            const result = await submitTask(taskId, file[taskId], urls[taskId], token);
            window.location.reload();
        } catch (error) {
            console.error("Error submitting task:", error);
        }
    };

    const renderTaskCards = (tasks, isCompleted = false) => {
        return tasks.map((task) => (
            <div key={task.id} className={styles.card}>
                <h2>{task.name}</h2>
                <p>
                    <img src={descriptionIcon} alt="Description Icon" className={styles.icon} />
                    <strong>Description:</strong> {task.description}
                </p>
                <p>
                    <img src={deadlineIcon} alt="Deadline Icon" className={styles.icon} />
                    <strong>Deadline:</strong> {new Date(task.deadline).toLocaleString()}
                </p>

                {!isCompleted ? (
                    <form className={styles.submitForm} onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(task.id);
                    }}>
                        <p className={styles.fileHint}>You can upload PDF file</p>
                        <input
                            type="file"
                            onChange={(e) => handleFileChange(task.id, e)}
                            className={styles.fileInput}
                            required
                            placeholder='Select a file'
                        />
                        {/* <br /> */}
                        <p className={styles.fileHint}>Upload Freelance job URL</p>
                        <input
                            type="text"
                            placeholder="Enter URL"
                            value={urls[task.id] || ""}
                            onChange={(e) => handleUrlChange(task.id, e)}
                            className={styles.urlInput}
                            required
                        />
                        {errors[task.id] && <h3 className={styles.errorText}>{errors[task.id]}</h3>}
                        <button type="submit" className={styles.submitButton}>Submit Task</button>
                    </form>
                ) : (
                    <div className={styles.reviewSection}>
                        <div className={styles.reviewComment}>
                            <h3 className={styles.reviewHeader}>Review Comment</h3>
                            <p>{task.review.comment}</p>
                        </div>
                        <div className={styles.lineBreak}></div>
                        <div className={styles.reviewDegree}>
                            <h3 className={styles.reviewHeader}>Review Degree</h3>
                            <p className={styles.degreeValue}>{task.review.degree}</p>
                        </div>
                    </div>
                )}
            </div>
        ));
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <div className={styles.container}>
            <div className={styles.tabsContainer}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    className={styles.infoTabs}
                    TabIndicatorProps={{ style: { display: 'none' } }}
                >
                    <Tab
                        label="Incomplete Tasks"
                        className={`${styles.navLink} ${tabValue === 0 ? styles.activeTab : ""}`}
                    />
                    <Tab
                        label="Completed Tasks"
                        className={`${styles.navLink} ${tabValue === 1 ? styles.activeTab : ""}`}
                    />
                </Tabs>
            </div>

            {tabValue === 0 ? (
                uncompleteTasks.length > 0 ? renderTaskCards(uncompleteTasks) : <p>No incomplete tasks.</p>
            ) : (
                completeTasks.length > 0 ? renderTaskCards(completeTasks, true) : <p>No completed tasks.</p>
            )}
        </div>
    );
};

export default TasksPage;
