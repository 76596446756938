import React, { useState, useEffect } from 'react';
import styles from './WebinarList.module.css';
import { API_URL, getToken } from '../../Helpers/constants';

function WebinarList() {
    const [webinars, setWebinars] = useState([]);

    useEffect(() => {
        const fetchWebinars = async () => {
            const myHeaders = new Headers();
            myHeaders.append('accept', 'application/json');
            myHeaders.append('Authorization', `Bearer ${getToken()}`);

            const requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            };

            try {
                const response = await fetch(`${API_URL}/webinars/`, requestOptions);
                const result = await response.json();
                setWebinars(result);
            } catch (error) {
                console.error('Error fetching webinars:', error);
            }
        };

        fetchWebinars();
    }, []);

    const handleStatusChange = async (webinarId, isFinished) => {
        const myHeaders = new Headers();
        myHeaders.append('accept', 'application/json');
        myHeaders.append('Authorization', `Bearer ${getToken()}`);
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('X-CSRFToken', '5JzmMFPhlVe1x6Luu7nIxndWwWjcPZE1QwWPWmIggCiLaL1GL5rdoj5gIXsj9Jl2');

        const raw = JSON.stringify({
            is_finished: isFinished,
        });

        const requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };

        try {
            const response = await fetch(`${API_URL}/webinars/${webinarId}/update-status/`, requestOptions);
            //eslint-disable-next-line
            const result = await response.json();
            
            // Update the local state to reflect the change in status
            setWebinars((prevWebinars) =>
                prevWebinars.map((webinar) =>
                    webinar.id === webinarId ? { ...webinar, is_finished: isFinished } : webinar
                )
            );
        } catch (error) {
            console.error('Error updating webinar status:', error);
        }
    };

    return (
        <div className={styles.webinarListContainer}>
            <div className={styles.webinarCards}>
                {webinars.length === 0 ? (
                    <p>No webinars available.</p>
                ) : (
                    webinars.map((webinar) => (
                        <div key={webinar.id} className={styles.webinarCard}>
                            <h3 className={styles.webinarName}>{webinar.name}</h3>
                            <p className={styles.webinarProfile}><strong>Job Profile Round:</strong> {webinar.job_profile_round}</p>
                            <p className={styles.webinarDate}><strong>Date:</strong> {new Date(webinar.date).toLocaleDateString()}</p>
                            <p className={styles.webinarTime}><strong>Time:</strong> {webinar.time}</p>
                            <p className={styles.webinarTime}><strong>Finished:</strong> {webinar.is_finished ? "Finished" : "Not Finished"}</p>

                            <a
                                href={webinar.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.webinarLink}
                            >
                                Webinar Link
                            </a>
                            <a
                                href={`webinar-attendance/${webinar.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.webinarLink}
                            >
                                Take Attendance
                            </a>

                            {/* Dropdown menu for changing webinar status */}
                            <div className={styles.statusDropdown}>
                                <select
                                    value={webinar.is_finished ? "finished" : "not_finished"}
                                    onChange={(e) =>
                                        handleStatusChange(webinar.id, e.target.value === "finished")
                                    }
                                >
                                    <option value="not_finished">Not Finished</option>
                                    <option value="finished">Finished</option>
                                </select>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default WebinarList;
